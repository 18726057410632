import React from "react"
import styled from "styled-components"

import downloadIcon from "../../assets/icons/download-icon.svg"

export const InfoSocialShareRow = ({
  slug,
  backgroundColor,
  iconColor,
  url,
  download,
}) => {
  const socialIcons = [
    {
      iconName: "twitter",
      iconPath: "/icons/twitter-grey-circ.svg",
      link: `https://twitter.com/intent/tweet?url=https://www.workgrid.com/${slug}`,
    },
    {
      iconName: "facebook",
      iconPath: "/icons/fb-grey-circ.svg",
      link: `https://www.facebook.com/sharer/sharer.php?u=https://www.workgrid.com/${slug}`,
    },
    {
      iconName: "linkedin",
      iconPath: "/icons/linkedin-grey-circ.svg",
      link: `https://www.linkedin.com/shareArticle?mini=true&url=https://workgrid.gatsbyjs.io/${slug}`,
    },
    {
      iconName: "instagram",
      iconPath: "/icons/insta-grey-circ.svg",
      link: "https://www.instagram.com/workgridsoftware/",
    },
  ]
  const socialIconsBlue = [
    {
      iconName: "twitter",
      iconPath: "/icons/twitter-blue.svg",
      link: `https://twitter.com/intent/tweet?url=https://www.workgrid.com//${slug}`,
    },
    {
      iconName: "facebook",
      iconPath: "/icons/facebook-blue.svg",
      link: `https://www.facebook.com/sharer/sharer.php?u=https://www.workgrid.com/${slug}`,
    },
    {
      iconName: "linkedin",
      iconPath: "/icons/linkedin-blue.svg",
      link: `https://www.linkedin.com/shareArticle?mini=true&url=https://workgrid.gatsbyjs.io/${slug}`,
    },
    {
      iconName: "instagram",
      iconPath: "/icons/instagram-blue.svg",
      link: "https://www.instagram.com/workgridsoftware/",
    },
  ]

  // //replace linkedin with before prod///
  // const pathName = typeof window !== 'undefined' ? encodeURIComponent(window.location.host) : '';
  // https://www.linkedin.com/shareArticle?mini=true&url=${pathName}/blog/${slug}

  const handleSocialShare = url => {
    // eslint-disable-next-line
    const left = (screen.width - 570) / 2
    // eslint-disable-next-line
    const top = (screen.height - 570) / 2
    const params =
      "menubar=no,toolbar=no,status=no,width=570,height=570,top=" +
      top +
      ",left=" +
      left
    window.open(url, "NewWindow", params)
  }

  return (
    <CompWrapper
      data-comp={InfoSocialShareRow.displayName}
      style={{ background: `${backgroundColor}` }}
    >
      <ShareWrap>
        <Title>SHARE</Title>
        <IconFlex>
          {!iconColor
            ? socialIcons.map(icon => (
                <>
                  <div
                    onClick={() => handleSocialShare(icon.link)}
                    onKeydown={() => handleSocialShare(icon.link)}
                    role="button"
                    tabIndex={0}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconWrapper>
                      <img
                        src={icon.iconPath}
                        alt={`${icon.iconName} share button`}
                      />
                    </IconWrapper>
                  </div>
                </>
              ))
            : socialIconsBlue.map(icon => (
                <>
                  <div
                    onClick={() => handleSocialShare(icon.link)}
                    onKeydown={() => handleSocialShare(icon.link)}
                    role="button"
                    tabIndex={0}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconWrapper>
                      <img
                        src={icon.iconPath}
                        alt={`${icon.iconName} share button`}
                      />
                    </IconWrapper>
                  </div>
                </>
              ))}
        </IconFlex>
      </ShareWrap>
      <DownloadWrap>
        <DownloadTitle>DOWNLOAD</DownloadTitle>
        <DownloadFlex>
          <a href={download} download>
            <IconWrapper>
              <img src={downloadIcon} alt={`download button`} />
            </IconWrapper>
          </a>
        </DownloadFlex>
      </DownloadWrap>
    </CompWrapper>
  )
}

InfoSocialShareRow.displayName = "InfoSocialShareRow"

const CompWrapper = styled.div`
  margin: 0 auto;
  border-radius: 6px;
  margin-top: 24px;
  max-width: 770px;
  @media (max-width: 768px) {
    max-width: 506px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`
const DownloadFlex = styled.div`
  position: relative;
  bottom: 20px;
`
const DownloadTitle = styled.div`
  padding: 32px 0px 16px 0px;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  position: relative;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #475467;
  position: relative;
  bottom: 23px;
  right: 26px;
`
const DownloadWrap = styled.div``
const ShareWrap = styled.div``

const Title = styled.div`
  padding: 32px 0px 16px 0px;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  position: relative;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #475467;
  bottom: 6px;
  right: 19px;
`

const IconFlex = styled.div`
  padding-right: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding-bottom: 32px;
  @media (max-width: 576px) {
    justify-content: space-evenly;
    gap: 5px;
  }
`

const IconWrapper = styled.div`
  cursor: pointer;
  img {
    transition: filter 200ms ease;
  }
  img:hover {
    filter: brightness(85%);
  }
`
